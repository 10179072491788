import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {connection, generateOtp, registration} from "../../Services/AuthServices";
import {getLastUrl, getUser, signIn} from "../../auth/Auth";
import {EMAIL_REGEX, PASSWORD_REGEX} from "../../Constants";
import ReactLoading from "react-loading";

function SignUp() {
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [is_checked, setIsChecked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [validate, setValidate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password:"",
    password_confirmation:"",
    is_checked:""
  });

  const resetErrors = () => {
    setErrors({
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      password_confirmation: "",
      is_checked: ""
    });
  };

  const errorsRequired = "Ce champ est réquis";
  const errorsNbWord = "La valeur est incorrecte"
  const errorsPasswordPattern = "Entrez un mot de passe 10 caractères minimum, composé de majuscules, chiffres et caractères spéciaux £#"
  const errorEmail = "Adresse mail invalide. Veuillez entrer une adresse mail valide"
  const errorsCkecked = "Veuillez accepter les termes et conditions"
  const errorsEqual = "Les deux mots de passe sont différents"

  let updatedErrors = {};

  const handleFormChange = (e) => {
    resetErrors();
    const {name, value} = e.target;
    setValidate(false);
    updatedErrors = {};
    switch (name) {
      case "firstName":
          setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "company":
        setCompany(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "password_confirmation":
        setPasswordConfirmation(value);
        break;
        case "checkbox_terms":
          setIsChecked(!is_checked);
        break;
      default:

    }

  };
  const formVerification = ()=>{
    let updatedErrors = {};

    if (firstName === "") {
      updatedErrors.firstName = errorsRequired;
    }

    if (lastName === "") {
      updatedErrors.lastName = errorsRequired;
    }

    if (email === "") {
      updatedErrors.email = errorsRequired;
    }else if(!email.match(EMAIL_REGEX)){
      updatedErrors.email = errorEmail;}

    if (password === "") {
      updatedErrors.password = errorsRequired;
    }else if(!password.match(PASSWORD_REGEX)){
      updatedErrors.password = errorsPasswordPattern;
    }

    if (password_confirmation === "") {
      updatedErrors.password_confirmation = errorsRequired;
    }else if(!password_confirmation.match(PASSWORD_REGEX)){
      updatedErrors.password_confirmation = errorsPasswordPattern;
    }

    if(password_confirmation !== password){
      updatedErrors.password = errorsEqual;
      updatedErrors.password_confirmation = errorsEqual;
    }

    if (is_checked === false) {
      updatedErrors.is_checked = errorsCkecked;
    }

      setErrors({
        ...errors,
        ...updatedErrors,
      });

    setValidate(true);

    return Object.keys(updatedErrors).length === 0;
  }
  const register =  async (e) => {
    let cond = formVerification();
    setIsLoading(true);
    if (cond) {
      try {
        e.preventDefault();

        const data = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          username: email,
          phone: phone,
          about_me:company,
          password_confirmation: password_confirmation,
        }
        // setLoading(true);
        const response = await registration(data);

        if (response) {
          setMessage(response.message);
          setSuccess(response.success)
        }
        resetForm();

      } catch (error) {

        const response = error.response.data;
        setMessage(response.message);
        setSuccess(response.success)
      }
    }
    setIsLoading(false);
  }


  const resetForm =()=>{
    setEmail("")
    setFirstName("")
    setLastName('')
    setPassword("")
    setPasswordConfirmation("")
    setPasswordConfirmation("")
    setIsChecked(false)
  }

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <Tabs className="wd-form-login tf-tab">
            <img src='media-intelligence-icon.png' className="mb-4" alt="logo"/>
            <h4>Creation de compte</h4>

            {message &&
                // <div className={`nofi-form ${!success ? 'bg-danger' : 'bg-primary'}`}>
                <div className="nofi-form text-danger">
                  <p>
                    {message}
                  </p>
                </div>}

            <div className="content-tab">
              <TabPanel className="inner animation-tab">
                <form action="/">
                  <div className="ip">
                    <label>
                      First name<span>*</span>
                    </label>
                    <input
                        type="text"
                        onChange={handleFormChange}
                        placeholder="First name"
                        value={firstName}
                        name="firstName"
                    />
                    {validate ?
                        <span style={{
                          color: "red",
                          fontWeight: "italic"
                        }}>{errors.firstName}</span> : ''}

                  </div>
                  <div className="ip">
                    <label>
                      Last name<span>*</span>
                    </label>
                    <input
                        type="text"
                        onChange={handleFormChange}
                        placeholder="Last name"
                        name="lastName"
                        value={lastName}
                    />
                    {validate ?
                        <span style={{
                          color: "red",
                          fontWeight: "italic"
                        }}>{errors.lastName}</span> : ''}
                  </div>
                  <div className="ip">
                    <label>
                      Email address<span>*</span>
                    </label>
                    <input
                        type="text"
                        onChange={handleFormChange}
                        placeholder="Email address"
                        name="email"
                        value={email}
                    />
                    {validate ?
                        <span style={{
                          color: "red",
                          fontWeight: "italic"
                        }}>{errors.email}</span>  : ''}
                  </div>
                  <div className="ip">
                    <label>
                      Phone
                    </label>
                    <input
                        type="text"
                        onChange={handleFormChange}
                        placeholder="Phone"
                        name="phone"
                        value={phone}
                    />
                  </div>
                  <div className="ip">
                    <label>
                      Company
                    </label>
                    <input
                        type="text"
                        onChange={handleFormChange}
                        placeholder="Company name"
                        name="company"
                        value={company}
                    />
                  </div>
                  <div className="ip">
                    <label>
                      Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                          type={`${showPass ? "text" : "password"}`}
                          className="input-form password-input"
                          onChange={handleFormChange}
                          placeholder="Password"
                          required
                          name="password"
                          value={password}
                      />
                      <Link
                          className={`password-addon ${
                              showPass ? "icon-eye" : "icon-eye-off"
                          }`}
                          onClick={() => setShowPass(!showPass)}

                      />

                    </div>
                    {validate ?
                        <span style={{
                          color: "red",
                          fontWeight: "italic"
                        }}>{errors.password}</span> : ''}
                  </div>
                  <div className="ip">
                    <label>
                      Confirm Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                          type={`${showPass2 ? "text" : "password"}`}
                          className="input-form password-input"
                          name="password_confirmation"
                          value={password_confirmation}
                          onChange={handleFormChange}
                          placeholder="Password"
                          required
                      />
                      <Link
                          className={`password-addon ${
                              showPass2 ? "icon-eye" : "icon-eye-off"
                          }`}
                          onClick={() => setShowPass2(!showPass2)}
                      />
                    </div>
                    {validate ? <span style={{
                          color: "red",
                          fontWeight: "italic"
                        }}>{errors.password_confirmation}</span>
                        : ''}
                  </div>
                  <div className="group-ant-choice st">
                    <div className="sub-ip">
                      <input type="checkbox"
                             name="checkbox_terms"
                             checked={is_checked}
                             onClick={handleFormChange}
                      />J'accepte les
                      <Link to="/termsofuse"> termes et conditions</Link>
                    </div>
                    {validate ? <span style={{
                          color: "red",
                          fontWeight: "italic"
                        }}>{errors.is_checked}</span>
                        : ''}
                  </div>
                  <div className="group-ant-choice st">
                  <button type="button"
                          //disabled={!is_checked}
                          onClick={register}>Créer mon compte</button>&nbsp;&nbsp;
                          {isLoading ?
                  <ReactLoading type={"spin"} color="red" height={50}
                                                                          width={(50)}/> : null}
                                                                          </div>
                  <div className="sign-up">
                    Déjà un compte?
                    <Link to="/login">&nbsp; Connexion</Link>
                  </div>
                </form>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
