import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {getLastUrl, getUser, isAuthenticated, logout, removeLastUrl, setLastUrl, signIn} from "../../auth/Auth";
import {connection, generateOtp} from "../../Services/AuthServices";

function SignOut() {

  useEffect(() => {

    const handleRedirect = async () => {

      logout();
      window.location.replace(process.env.REACT_APP_CLIENT_SERVICE);

    }
    handleRedirect();

  }, []);


  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
         <h1>Log in out </h1>
        </div>
      </div>
    </section>
  );
}

export default SignOut;
