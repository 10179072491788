import React from "react";

import ResetPassword from "../components/reset-password";
import { useState, useEffect } from "react";

function Resetpassword(props) {
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };


  useEffect(() => {

    //window.location.replace(process.env.REACT_APP_SSO_SERVICE + "/login?redirect="+window.location.host);
  
    }, []);

  return (
    <>

      <ResetPassword />
    </>
  );
}

export default Resetpassword;
