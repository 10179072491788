import React, {useContext} from "react";
import {validateOtp} from "./services/AuthServices";
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";

import Login from "./pages/Login";
import { useEffect } from "react";
import ScrollToTop from "./ScrollToTop";
import { useState } from "react";
import { AuthContext } from "./context/AuthContext";
import ResetPassword from "./pages/ResetPassword";
import Account from "./components/account";
import {isAuthenticated, setLastUrl} from "./auth/Auth";
import Preloader from "./components/preloader";
import CreateAccount from "./pages/CreateAccount";
import SignOut from "./components/logout";
import SuccessAccount from "./components/success-account-creation";
const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/reset-password", element: <ResetPassword /> },
    { path: "/myaccount", element: <Account /> },
    { path: "/logout", element: <SignOut /> },
    { path: "/register", element: <CreateAccount /> },
    { path: "/email-verify-success", element: <SuccessAccount /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  const [loading, setLoading] = useState(true);
  const { connected, user, afterLoginLight } = useContext(AuthContext);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    const fetchLoginUrl = () => {
      const queryParams = new URLSearchParams(window.location.search);
      let microserviceUrl = queryParams.get("redirect");
      if(microserviceUrl){
        if(isAuthenticated()){
          window.history.pushState({}, '', "/myaccount");
        }else{
          setLastUrl(microserviceUrl);
        }
      }
    }
    fetchLoginUrl();
  }, []);

  return (
    <>
      {!loading ? (
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default AppWrapper;
