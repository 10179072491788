import React, {useEffect} from "react";
import {getLastUrl, getUser, isAuthenticated} from "../../auth/Auth";
import {generateOtp} from "../../Services/AuthServices";
import {useNavigate} from "react-router-dom";
import {testUrlValid} from "../../Constants";

function Account() {

  const navigates = useNavigate();

  useEffect(() => {

    const handleRedirect = async () => {

      let lastUrl = getLastUrl();

      let cond = testUrlValid(lastUrl);

      if (cond) {
        if (isAuthenticated()) {
          const response = await generateOtp(getUser().id);
          const otp = response.data.otp.otp;
          if (otp) {
           // removeLastUrl();
            //navigates(microserviceUrl + "?otp=" + otp, { replace: true });
            //window.location.replace(microserviceUrl + "?otp=" + otp);
            //window.location.href = microserviceUrl + "?otp=" + otp;
            window.location.assign(lastUrl + "?otp=" + otp);
          }
        }
      }

    }
    handleRedirect();

  }, []);

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <div className="wd-form-login">
            <h4>My account</h4>
            {/*<div className="nofi-form">*/}
            {/*  <p>*/}
            {/*    Username: <span>candidate</span> or <span>employer</span>*/}
            {/*  </p>*/}
            {/*  <p>*/}
            {/*    Password: <span>jobtex</span>*/}
            {/*  </p>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Account;
