
export const TOKEN_KEY = "token";
export const EXPIRE_AT_KEY = "expired_at";
export const ROLE_KEY = "role";
export const USER_KEY = "user";
export const LAST_URL = "LAST_URL";

export const saveAuthToken = (accessToken, expiredAt) => {
    localStorage.setItem(TOKEN_KEY, accessToken);
    localStorage.setItem(EXPIRE_AT_KEY, expiredAt.toString());
};

export const setLastUrl = (url) => {
    localStorage.setItem(LAST_URL, url);
}
export const removeLastUrl = (url) => {
    let last = getLastUrl();
    if (last != null) {
        localStorage.removeItem(LAST_URL);
    }
}
export const getLastUrl = () => {
    return localStorage.getItem(LAST_URL);
}
export const removeAuthToken = () => {
    localStorage.removeItem(EXPIRE_AT_KEY);
    localStorage.removeItem(TOKEN_KEY);
};

export const getUser = () => {
    return JSON.parse(localStorage.getItem(USER_KEY));
}

export const signIn = (param) => {
    const data = param.data;
    localStorage.setItem("user_id", data.user_id);
    localStorage.setItem(TOKEN_KEY, data.token);
    localStorage.setItem(USER_KEY, JSON.stringify(data.user));
};

export const logout = () => {
    // Clear access token and ID token from local storage
    //UserService.logout((data)=>{ alert(data.date);});
    // const { message, success } = await UserService.logoutAsync();
    //alert(success);
    //alert(message);

    removeLastUrl();
    localStorage.removeItem("user_id");
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
};

export const isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    return  localStorage.getItem(USER_KEY) !== null;
};
