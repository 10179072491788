import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {getLastUrl, getUser, signIn} from "../../auth/Auth";
import {EditPassword, generateOtp} from "../../Services/AuthServices";
import {testUrlValid} from "../../Constants";

function ResetPassword() {
  const navigates = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [openAlert, setOpenAlert] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const loginUser = async (e) => {
    try {
      e.preventDefault();

      const data = {
        email: localStorage.getItem("temp_email"),
        password: password,
        password_confirmation: password_confirmation,
      }
      // setLoading(true);
      const response = await EditPassword(data);

      signIn(response);
      const lastUrl = getLastUrl();
      let cond = testUrlValid(lastUrl);

      if(cond) {

        const response = await generateOtp(getUser().id);

        const otp = response.data.otp.otp;
        if (otp) {
          //removeLastUrl();
          setAlertMessage("Success! Your OTP has been sent to your email. Please check your email")
          //navigates(lastUrl + "?otp=" + otp, { replace: true });
          //window.location.replace( lastUrl + "?otp=" + otp);
          //window.location.href = lastUrl + "?otp=" + otp;
          window.location.assign(lastUrl + "?otp=" + otp);
        }
        return
      }
      //afterLogin();
      navigates("/myaccount");
      return;

    } catch (error) {
      if (error.status === 403) {
        let email = error.data.message;

      } else {

        //console.log(error.data.message);
      }
    }
    //setLoading(false);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <div className="wd-form-login">
            <img src='media-intelligence-icon.png' className="mb-4" alt="logo"/>
            <h4>Reset password</h4>
            {/*<div className="nofi-form">*/}
            {/*  <p>*/}
            {/*    Username: <span>candidate</span> or <span>employer</span>*/}
            {/*  </p>*/}
            {/*  <p>*/}
            {/*    Password: <span>jobtex</span>*/}
            {/*  </p>*/}
            {/*</div>*/}
            <form action="/">
              <div className="ip">
                <label>
                  Password<span>*</span>
                </label>
                <div className="inputs-group auth-pass-inputgroup">
                  <input
                      type={showPass ? "text" : "password"}
                      className="input-form password-input"
                      placeholder="Password"
                      id="password-input"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                  />
                  <Link
                      className={`password-addon ${
                          showPass ? "icon-eye" : "icon-eye-off"
                      }`}
                      id="password-addon"
                      onClick={() => setShowPass(!showPass)}
                  />
                </div>
              </div>
              <div className="ip">
                <label>
                  Password confirmation<span>*</span>
                </label>
                <div className="inputs-group auth-pass-inputgroup">
                  <input
                      type={showPass ? "text" : "password"}
                      className="input-form password-input"
                      placeholder="Password"
                      id="password-input"
                      required
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                  />
                  <Link
                      className={`password-addon ${
                          showPass ? "icon-eye" : "icon-eye-off"
                      }`}
                      id="password-addon"
                      onClick={() => setShowPass(!showPass)}
                  />
                </div>
              </div>
              {/*<div className="group-ant-choice">*/}
              {/*  <div className="sub-ip">*/}
              {/*    <input type="checkbox" />*/}
              {/*    Remember me*/}
              {/*  </div>*/}
              {/*  <Link to="#" className="forgot">*/}
              {/*    Forgot password?*/}
              {/*  </Link>*/}
              {/*</div>*/}
              {/*<p className="line-ip">*/}
              {/*  <span>or sign up with</span>*/}
              {/*</p>*/}
              {/*<Link to="#" className="btn-social">*/}
              {/*  Continue with Facebook*/}
              {/*</Link>*/}
              {/*<Link to="#" className="btn-social">*/}
              {/*  <img src={img} alt="images" /> Continue with Google*/}
              {/*</Link>*/}
              {/*<Link to="#" className="btn-social">*/}
              {/*  <img src={img2} alt="images" /> Continue with Twitter*/}
              {/*</Link>*/}
              <button type="submit" onClick={loginUser}>Login</button>
              {/*<div className="sign-up">*/}
              {/*  Not registered yet? <Link to="/createaccount">Sign Up</Link>*/}
              {/*</div>*/}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
