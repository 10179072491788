import axios from "axios";

export const connection = (data) => {
    let loginFormData = new FormData();

    loginFormData.append("email", data.email);
    loginFormData.append("password", data.password);
    loginFormData.append("remember", data.remember);

    return new Promise((resolve, reject) => {
        axios
            .post('/login', loginFormData)
            .then( (response) => {
                resolve(response);
            })
            .catch( (error) => {
                reject(error.response);
            });
    });
};

export const registration = (data) => {

    let registerFormData = new FormData();

    registerFormData.append("username", data.username);
    registerFormData.append("first_name", "");
    registerFormData.append("last_name", "");
    registerFormData.append("email", data.email);
    registerFormData.append("password", data.password);
    registerFormData.append("password_confirmation", data.password_confirmation);
    registerFormData.append("question", "");
    registerFormData.append("answer", "");

    return new Promise((resolve, reject) => {
        axios
            .post('/register', registerFormData)
            .then( (response) => {
                resolve(response.data);
            })
            .catch( (error) => {
                reject(error);
            });
    });
};

export const generateOtp = (id) => {

    return new Promise((resolve, reject) => {
        axios
            .get(`/generate/otp/${id}`)
            .then( (response) => {
                resolve(response.data);
            })
            .catch( (error) => {
                reject(error.response);
            });
    });
};

export const EditPassword = (data) => {

    let registerFormData = new FormData();

    registerFormData.append("email", data.email);
    registerFormData.append("password", data.password);
    registerFormData.append("password_confirmation", data.password_confirmation);

    return new Promise((resolve, reject) => {
        axios
            .post('/edit/password', registerFormData)
            .then( (response) => {
                resolve(response.data);
            })
            .catch( (error) => {
                reject(error);
            });
    });
};