import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {getLastUrl, getUser, isAuthenticated, removeLastUrl, setLastUrl, signIn} from "../../auth/Auth";
import {connection, generateOtp} from "../../Services/AuthServices";
import {testUrlValid} from "../../Constants";
import ReactLoading from "react-loading";

function SignIn() {
  const [showPass, setShowPass] = useState(false);
  const navigates = useNavigate();
  //const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loginUser = async (e) => {
    setIsLoading(true);
    try {
      e.preventDefault();

      const data = {
        email: email,
        password: password,
        remember: true
      }

      // setLoading(true);
      const response = await connection(data);

      localStorage.setItem("temp_email", email);

      if(response.data.data.user.is_first_connection === 1){
        navigates("/reset-password")
        return;
      }

      signIn(response.data);
      const lastUrl = getLastUrl();
      let cond = testUrlValid(lastUrl);

      if(cond) {

        const response = await generateOtp(getUser().id);

        const otp = response.data.otp.otp;
        if (otp) {
          //removeLastUrl();
          setAlertMessage("Success! Your OTP has been sent to your email. Please check your email")
          //navigates(lastUrl + "?otp=" + otp, { replace: true });
          //window.location.replace(lastUrl + "?otp=" + otp);
          //window.location.href = lastUrl + "?otp=" + otp;

          window.location.assign(lastUrl + "?otp=" + otp);
          return;
        }
      }
      //afterLogin();
      navigates("/myaccount");


    } catch (error) {
      console.log(error);
      if (error.status === 403) {
        let email = error.data.message;
        console.log(email);
        // history({
        //         pathname: '/verify-email',
        //     }
        // );
      } else {
        // showAlert("error", error.data.message)
        //console.log(error.data.message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {

    const handleRedirect = async () => {

      const lastUrl = getLastUrl();

      let cond = testUrlValid(lastUrl);

      if (cond) {
        setLastUrl(lastUrl);

        if (isAuthenticated()) {
          //return
          const response = await generateOtp(getUser().id);
          const otp = response.data.otp.otp;
          if (otp) {
            //removeLastUrl();
            //navigates(microserviceUrl + "?otp=" + otp, { replace: true });
           // window.location.replace(microserviceUrl + "?otp=" + otp);
           // window.location.href = microserviceUrl + "?otp=" + otp;
            window.location.assign(lastUrl + "?otp=" + otp);
          }
        }
      }

    }
    handleRedirect();

  }, []);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">

          <div className="wd-form-login">
            <img src='media-intelligence-icon.png' className="mb-4" alt="logo"/>
            <h4>Identifiez vous</h4>
            {/* <div className="nofi-form">
              <p>
                Username: <span>candidate</span> or <span>employer</span>
              </p>
              <p>
                Password: <span>jobtex</span>
              </p>
            </div> */}

            <form action="/">
              <div className="ip">
                <label>
                  Username or email address<span>*</span>
                </label>
                <input
                    type="text"
                    //defaultValue="Tony Nguyen"
                    placeholder="Name"
                    onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="ip">
                <label>
                  Password<span>*</span>
                </label>
                <div className="inputs-group auth-pass-inputgroup">
                  <input
                      type={showPass ? "text" : "password"}
                      className="input-form password-input"
                      placeholder="Password"
                      id="password-input"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                  />
                  <Link
                      className={`password-addon ${
                          showPass ? "icon-eye" : "icon-eye-off"
                      }`}
                      id="password-addon"
                      onClick={() => setShowPass(!showPass)}
                  />
                </div>
              </div>
              <div className="group-ant-choice">
                <div className="sub-ip">
                  <input type="checkbox"/>
                  Se souvenir de moi
                </div>
                <Link to="#" className="forgot">
                  Mot de passe oublié?
                </Link>
              </div>
              {/*<p className="line-ip">*/}
              {/*  <span>or sign up with</span>*/}
              {/*</p>*/}
              {/*<Link to="#" className="btn-social">*/}
              {/*  Continue with Facebook*/}
              {/*</Link>*/}
              {/*<Link to="#" className="btn-social">*/}
              {/*  <img src={img} alt="images" /> Continue with Google*/}
              {/*</Link>*/}
              {/*<Link to="#" className="btn-social">*/}
              {/*  <img src={img2} alt="images" /> Continue with Twitter*/}
              {/*</Link>*/}
              <div className="group-ant-choice st">
              <button type="submit" onClick={loginUser}>Connexion</button>{isLoading ?
                  <ReactLoading type={"spin"} color="red" height={50}
                                                                          width={(50)}/> : null}
                                                                          </div>
              <div className="sign-up">
               Pas de compte? <Link to="/register">Créer un compte</Link>
              </div>

            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
